//components
import StartMain from '../components/StartMain'
import Icons from '../components/Icons'
import Counters from '../components/Counters'
import StartOffer from '../components/StartOffer'
import Portfolio from '../components/Portfolio'
import Contact from '../components/Contact'
// import Slider from '../components/Slider'
import Bio from '../components/Bio'
import Hero from '../components/Hero'

import Gallery from '../components/Gallery'
import Separator from '../components/Separator'

export default function App() {

  return (
    <>
    <Hero />
    <StartMain />
    <div className='top-50'>
      <Gallery imgNumber={14} url={'images'} place={'galeria'}/>
    </div>
    <Separator />
    <Icons />
    <Counters />
    <StartOffer />
    <Portfolio />
    <Bio />
    <Contact />
    </>
  )
}