import { NavLink } from "react-router-dom"

export default function HeroBtns() {
  return (
    <div className="hero-buttons">
      <NavLink to='/portfolio'><button className="hero-btn hero-btn-a">Zobacz moje Portfolio</button></NavLink>
      <NavLink to='/kontakt'><button className="hero-btn hero-btn-b">Sprawdź dostępne terminy</button></NavLink>
    </div>
  )
}
