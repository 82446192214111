//components
import Logo from './Logo'
import Navigation from './Navigation'
import Socials from './Socials'

export default function Header({ handleModal }) {
	return (
        <div id='header' className='flex justify-center align-center header-box'>
            <div className="flex justify-between align-center header">
                <Logo />
                <div className='flex justify-between align-center menu-desktop'>
                    <Navigation />
                    <Socials cssClass={'header-icons'} />
                </div>
                <div className='mobile-menu-icon'><h3 onClick={handleModal}>|||</h3></div>
            </div>
        </div>
	)
}