//components import
import Bio from '../components/Bio'

export default function BioPage() {
  return (
    <div id='bio-page' className='flex column justify-center align-center full-width'>
     <Bio />
    </div>
  )
}
