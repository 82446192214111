//basic
import { motion } from 'framer-motion'
import { useState } from 'react'

//components
import BlogCard from '../components/BlogCard'
import Blogheading from '../components/Blogheading'
import Tags from "../components/Tags"

//custom hooks
import { useCollection } from '../hooks/useCollection'

export default function Blog() {
    const { documents } = useCollection('blog')
    const [filter, setFilter] = useState('wszystkie')

    const changeFilter = (newFilter) => {
        setFilter(newFilter)
        console.log(filter)
    }

    const projects = documents ? documents.filter(document => {
        switch(filter) {
          case 'wszystkie':
            return true
          case filter:
            console.log(document.tags, filter)
            return document.tags.includes(filter)
          default:
            return true
        }
    }) : null

    const variants = {
        active: {
            scale: .95,
            rotate: -3,
            transition: {
                duration: 0.9,
                type: "spring", 
                stiffness: 100
            }
        }
    }

    return (
        <>
        <Blogheading />
        <div className="blog-heading-tags flex justify-center align-center full-width">
            <Tags changeFilter={changeFilter}/>
        </div>
        <div className='post-grid'>
            {projects && projects.map(document => (
                <motion.div className='post-card flex column justify-center align-center' key={document.url} whileHover='active' variants={variants}>
                    <div>
                        <BlogCard document={document} />
                    </div>
                </motion.div>
            ))}
        </div>
        </>
    )
    }