import { useState, useEffect } from "react";

import { FaCameraRetro } from "react-icons/fa";
import { BiSolidCameraMovie } from "react-icons/bi";
import { BsCameraReelsFill } from "react-icons/bs";
import { FaCamera } from "react-icons/fa";

export default function HeroHeader() {

  const profs = ['Fotograf', 'Kamerzysta']

  const [id, setId] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (id >= 1) {
        setId(prevId => prevId - 1);
      } else {
        setId(prevId => prevId + 1);
      }
    }, 2500);
    return () => clearInterval(interval);
  }, [id, setId]);

  return (
    <div className='hero-header'>
      <h4>Twój</h4>
      <h1>{profs[id]} <span className="header-icon">{id === 0 ? <FaCamera /> : <BsCameraReelsFill />}</span> </h1>
    </div>
  )
}
