//basic
import { ReactMarkdown } from "react-markdown/lib/react-markdown"

//custom hooks
import { useCollection } from "../hooks/useCollection"

export default function Blogheading() {

  const { documents } = useCollection('blog-page')

  return (
    <>
    {documents && 
      <div className="blog-heading-section flex column justify-center align-center full-width">
        <div className="blog-heading-section flex column justify-center align-center fixed-width">
          <div className="blog-heading-h text-center">
            <ReactMarkdown>{documents[0].title}</ReactMarkdown>
          </div>
          <div className="blog-heading-content flex justify-center align-center res-column">
            <div className="blog-heading-p">
              <ReactMarkdown>{documents[0].heading}</ReactMarkdown>
            </div>
            <div className="blog-heading-img w-40">
              <img src={documents[0].img} alt='avatart' />
            </div>
          </div>
        </div>
      </div>
    }
    </>
  )
}
