//basic
import ReactMarkdown from 'react-markdown'

//components
import Pagination from '../components/Pagination';

export default function BlogContent({document}) {

    const postContent = document.text

    const post = postContent.replaceAll('%br%', "&nbsp; \n\n")

    console.log(post)

    return (
        <div className='post-content flex column justify-center max-w-60'>
            <ReactMarkdown children={post}/>
            <Pagination document={document}/>
        </div>
    )
}
