export default function HeroCard({card}) {

  console.log(card)

  return (
    <div className='hero-card'>
      <div className="hero-card-value">
        <h2><span>{card.value}</span></h2>
      </div>
      <div className="hero-card-desc">
        <h4>{card.heading}</h4>
        <h4>{card.desc}</h4>
      </div>
    </div>
  )
}
