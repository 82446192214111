//basic
import { motion } from 'framer-motion'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown.js'

//custom hooks
import { useCollection } from '../hooks/useCollection.js'

//components
import Separator from './Separator'
import Services from './Services'

export default function StartOffer() {

	const { documents } = useCollection('start-offer')

	const variants = {
		iLeft: {
			x: -200,
			opacity: 0
		},
		iRight: {
			x: 200,
			opacity: 0
		},
		final: {
			x: 0,
			opacity: 1,
			transition: {
				delay: .3,
				duration: .8
			}
		}
	}

	return (
		<>
		<div id='offer' className='flex column justify-center align-center full-width'>
			<h2 className='text-center fixed-width-700'>Oferta fotografii i wideo ŚLUBNEGO, OKOLICZNOŚCIOWEGO, INDYWIDUALNEGO i KOMERCYJNEGO</h2>
			<div id="_oferta" className='flex column fixed-width'>
				{documents && documents.map(item => {
					if (item.item % 2 === 0) {
						return <div key={item.item} className='flex justify-center align-center offer-row res-column res-inver'>
							<div className='offer-text w-50 flex column justify-center align-start'>
								<motion.div className='full-width' initial="iLeft" whileInView="final" viewport={{ once: true }} variants={variants}><ReactMarkdown>{item.heading}</ReactMarkdown></motion.div>
								<ReactMarkdown>{item.text}</ReactMarkdown>
							</div>
							<motion.div 
								className='flex justify-end align-center offer-img w-50'>
								<motion.img src={item.img} alt={item.alt} className='mono' whileHover={{ scale: 1.1, transition: { duration: 1 } }} />
							</motion.div>
						</div>
					} else {
						return <div key={item.item} className='flex justify-center align-center offer-row res-column'>
							<motion.div 
								className='offer-img w-50 flex column justify-center align-start'>
								<motion.img src={item.img} alt={item.alt} className='mono' whileHover={{ scale: 1.1, transition: { duration: 1 } }} />
							</motion.div>
							<div className='flex column justify-end align-end offer-text w-50 left-20'>
								<motion.div className='full-width text-right' initial="iRight" whileInView="final" viewport={{ once: true }} variants={variants}><ReactMarkdown>{item.heading}</ReactMarkdown></motion.div>
								<p className='text-right'><ReactMarkdown>{item.text}</ReactMarkdown></p>
							</div>
						</div>
					}
				})}
			</div>
			<Services />
		</div>
		<Separator />			
		</>
	)
}