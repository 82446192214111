//basic
import ReactMarkdown from 'react-markdown'
import { motion } from 'framer-motion'

//custom hooks
import { useDocument } from '../hooks/useDocument'

//components
import Separator from './Separator'

export default function Portfolio() {

  const { document, error } = useDocument('start-other-sections', 'portfolio-section')

  const variants = {
		iLeft: {
			x: -200,
			opacity: 0
		},
		iRight: {
			x: 200,
			opacity: 0
		},
		final: {
			x: 0,
			opacity: 1,
			transition: {
				delay: .5,
				duration: .8
			}
		}
	}

  return (
    <>
    {error && <div>{error}</div>}
    {document && (
      <div id='portfolio' className='flex column justify-center align-center full-width'>
        <img src='../assets/separator_tlo.svg' alt='white background' className='background-top' />
        <div className='flex column justify-center align-center fixed-width'>
          <motion.h2 className='text-center' initial="iLeft" whileInView="final" viewport={{ once: true }} variants={variants}>{document.heading}</motion.h2>
          <ReactMarkdown className='text-center'>{document.text}</ReactMarkdown>
          <img src={document.img} alt={document.alt} className='mono portfolio-img' />
        </div>
        <img src='../assets/separator_tlo.svg' alt='white background' className='background-bottom' />
      </div>
    )}
    <Separator />
    </>
  )
}
