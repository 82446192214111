//basic
import { useParams } from 'react-router'
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";

//custom hooks
import { useDocument } from '../hooks/useDocument'

//components
import BlogContent from '../components/BlogContent'
import BlogGallery from '../components/BlogGallery'


export default function BlogPost() {
  const postId = useParams()
  const { document, error } = useDocument('blog', postId.id)

  return (
    <>
    {error && <div>{error}</div>}
    {document && 
      <div className='flex column justify-center align-center full-width blog-post'>
        <h1 className='post-title'>{document.title}</h1>
        <p className='post-meta'><FaUserAlt/>{document.author}   <span>|</span>   <FaCalendarAlt/>{document.postDate}</p>
        <p className='post-tags'>{document.tags.map(tag => <span key={tag}>{` | ${tag}`}</span>) }</p>
        <div className='flex justify-center fixed-width res-column'>
          <BlogContent document={document}/>
          <BlogGallery document={document}/>
        </div>
      </div>
    }
    </>
  )
}
