//basic
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

//icons
import { FaEnvelope } from 'react-icons/fa';
import { FaPhoneSquare } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaFacebookMessenger } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { Fa500Px } from 'react-icons/fa';

//custom hooks
import { useCollection } from '../hooks/useCollection';

export default function Socials({ cssClass }) {

  const [ initialColor, setInitialColor] = useState('')

  const { documents } = useCollection('contact')

  const icons = [<FaEnvelope />, <FaPhoneSquare />, <FaFacebook />, <FaFacebookMessenger />, <FaInstagram />, <FaYoutube />, <Fa500Px />]

  useEffect(() => {
    if (cssClass === 'contact-icons') {
      setInitialColor('rgb(0, 0, 0)')
    } else {
      setInitialColor('rgb(255, 255, 255)')
    }
  }, [cssClass]) 
  
  const parent = {
    initial: {
      color: initialColor
    },
    animate: {
      color: 'rgb(255, 165, 0)',
      transition: { 
        duration: 0.3
      }
    }
  }

  const child = {
    initial: {
      color: initialColor
    },
    animate: {
      color: 'rgb(255, 165, 0)',
      transition: { 
        duration: 0.3
      }
    }
  }
  
  return (
    <>
      {documents &&
        <div className={cssClass}>
          <div className='social-icons flex justify-center align-center wrap'>
              {documents.map(document => (
                <motion.a href={document.url} key={document.id} initial='initial' whileHover='animate' variants={parent}>
                  <motion.span className='social-icon' variants={child}>{icons[document.ico]}</motion.span>
                  <motion.span className='social-span' variants={child}>{document.name}</motion.span>
                </motion.a>
              ))}
          </div>
        </div>
      }
    </>
  )
}
