import Gallery from './Gallery'

export default function BlogGallery({document}) {
  console.log(document.imgNumber)
  return (
    <>
    <div className='max-w-40'>
      <div>
        <div className={`blog-gallery ${document.colSize}`}>
          <Gallery imgNumber={document.imgNumber} url={document.url} place={'blog'}/>
        </div>
      </div>
      <div className='post-avatar flex column justify-center align-center'>
        <img src='../assets/hubertpulik.jpg' alt='hubert pulik i świnka' />
        <h3 className='text-center'>Hubert Pulik</h3>
      </div>
    </div>
    </>
  )
}
