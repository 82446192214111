//basic imports
import ReactMarkdown from 'react-markdown'
import { motion } from 'framer-motion'

//custom hooks
import { useDocument } from '../hooks/useDocument'

//components
import Separator from './Separator'

export default function Bio() {

  const { document, error } = useDocument('start-other-sections', 'bio-section')

  const variants = {
		iLeft: {
			x: -200,
			opacity: 0
		},
		iRight: {
			x: 200,
			opacity: 0
		},
		final: {
			x: 0,
			opacity: 1,
			transition: {
				delay: .5,
				duration: .8
			}
		}
	}

  return (
    <>
    {error && <div>{error}</div>}
    {document && (
      <div id='bio' className='flex column justify-center align-center full-width'>
        <img src='../assets/separator_tlo.svg' alt='white background' className='background-top' />
        <div className='flex justify-center align-center fixed-width res-column'>
          <div>
            <motion.h2 initial="iLeft" whileInView="final" viewport={{ once: true }} variants={variants}>{document.heading}</motion.h2>
            <ReactMarkdown>
              {document.text}
            </ReactMarkdown>
          </div>
          <div>
            <img src={document.img} alt={document.alt} />
          </div>
        </div>
        <img src='../assets/separator_tlo.svg' alt='white background' className='background-bottom' />
      </div>
    )}
    <Separator />
    </>
  )
}
