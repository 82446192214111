//basic
import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//styles
import './App.css'
import './css/Layout.css'
import './css/Blog.css'
import './css/Pages.css'
import './css/Header.css'
import './css/Start.css'
import './css/Footer.css'

//components
import Header from './components/Header'
import Mobilemenu from './components/Mobilemenu'
import Modal from './components/Modal'
import Footer from './components/Footer'

//pages
import Start from './pages/Start'
import Portfolio from './pages/Portfolio'
import Oferta from './pages/Oferta'
import BioPage from './pages/BioPage'
import Kontakt from './pages/Kontakt'
import BlogPost from './pages/BlogPost'
import Blog from './pages/Blog'
import AddBlogPost from './pages/AddBlogPost'


export default function App() {

const [isModal, setIsModal] = useState(false)

const handleModal = () => {
  setIsModal(prevIsModal => !prevIsModal)
  console.log(isModal)
}

  return (
    <main>
    <BrowserRouter>
      <Header handleModal={handleModal}/>
      <Modal isModal={isModal}>
        <Mobilemenu handleModal={handleModal}/>
      </Modal>
      <Routes>
        <Route path='/' element={<Start />} />
        <Route path='oferta' element={<Oferta />} />
        <Route path='portfolio' element={<Portfolio />} />
        <Route path='bio' element={<BioPage />} />
        <Route path='kontakt' element={<Kontakt />} />
        <Route path='blog' element={<Blog />} />
        <Route path='blog/:id' element={<BlogPost />} />
        <Route path='dodaj-post' element={<AddBlogPost />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </main>
  )
}