import { useState, useEffect } from "react";

export default function HearoImage() {

  const images = ['../assets/hubert pulik fotograf web.jpg', '../assets/hubert pulik kamerzysta web.jpg']

  const [id, setId] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (id >= 1) {
        setId(prevId => prevId - 1);
      } else {
        setId(prevId => prevId + 1);
      }
    }, 2500);
    return () => clearInterval(interval);
  }, [id, setId]);


  return (
    <div className='hearo-image'>
      <img className="hero-img" src={images[id]} alt="Hubert Pulik fotograf i kamerzysta"/>
    </div>
  )
}