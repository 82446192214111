//basic
import CountUp from 'react-countup';

//custom hooks
import { useCollection } from '../hooks/useCollection'

//components
import Separator from './Separator'

export default function Counters() {

  const { documents } = useCollection('start-counters')

  return (
    <>
    <div id='counters' className='flex column justify-center align-center full-width'>
      <img src='../assets//separator_tlo.svg' alt='white background' className='background-top' />
      <div className='flex fixed-width justify-center align-center res-column'>
        {documents && documents.map(item => (
          <div className='counter-box flex column justify-center align-center' key={item.id}>
            <h2 className='text-center'><CountUp end={item.count} enableScrollSpy={true} /></h2>
            <p className='text-center'>{item.text}</p>
          </div>
        ))}
      </div>
      <img src='../assets//separator_tlo.svg' alt='white background' className='background-bottom' />
    </div>
    <Separator />
    </>
  )
}