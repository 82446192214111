//basic
import { useState } from "react"

export default function Gallery({imgNumber, url, place}) {
    const [isLightbox, setIsLightbox] = useState(null)

    const images = []

    for (let i = 1; i < imgNumber + 1; i++) {
        images.push(
            {
                src:`../assets/${place}/${url}/tmb/${i}.jpeg`,
                key: `b${i}`,
                id: i
            }
        )
    }

    const handleClick = (id) => {
       setIsLightbox(id)
    }

    const navigateImg = (e) => {
        if (e === 'prev') {
            isLightbox === 1 ? setIsLightbox(imgNumber) : setIsLightbox(prevIsLightbox => prevIsLightbox - 1)
        } else if (e === 'next') {
            isLightbox === imgNumber ? setIsLightbox(1) : setIsLightbox(prevIsLightbox => prevIsLightbox + 1)
        }
    }
    
   // onKeyDown={(e) => navigateImg(e)}

    return (
        <>
            <div className="gallery-grid">
                {images.map(image => 
                    <img 
                        src={image.src} 
                        alt='Zdjęcie autorstwa Huberta Pulika' 
                        key={image.key} 
                        id={image.id}
                        onClick={() => handleClick(image.id)}
                    />
                )}
            </div>
            {isLightbox && (
                <div className='modal-overlay flex column'>
                    
                    <img src={`./assets/${place}/${url}/${isLightbox}.jpeg`} alt='Zdjęcie autorstwa Huberta Pulika' className="lightbox-img" />
                    <div className="flex justify-between width-80">
                    <div><h2 onClick={() => navigateImg('prev')}>Prev</h2></div>
                    <div><h2 onClick={() => handleClick(null)}>X</h2></div>
                    <div><h2 onClick={() => navigateImg('next')}>Next</h2></div>
                    </div>
                    
                </div>
            )
            }
        </>
    )
}
