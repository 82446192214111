//basic
import { useState } from "react"

//firebase
import { db } from "../firebase/config"
import { doc, setDoc } from "firebase/firestore"

//custom hook
import { useDocument } from '../hooks/useDocument'

export default function AddBlogPost() {
    const [url, setUrl] = useState('')
    const [title, setTitle] = useState('')
    const [tags, setTags] = useState([])
    const [author, setAuthor] = useState('')
    const [postDate, setPostDate] = useState('')
    const [description, setDescription] = useState('')
    const [text, setText] = useState('')
    const [imgNumber, setImgNumber] = useState(0)
    const [colSize, setColSize] = useState('')
    
    const [ postId, setPostId ] = useState('czym-jest-plik-raw')
    const [ postPrefix, setPostPrefix ] = useState('')
    const { document, error } = useDocument('blog', postId)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const ref = doc(db, 'blog', url)

        const blogPost = {
            url,
            title,
            tags,
            author,
            postDate,
            description,
            text,
            imgNumber,
            colSize
        }

        await setDoc(ref, blogPost)

        console.log(blogPost)

        setUrl('')
        setTitle('')
        setTags([])
        setAuthor('')
        setPostDate('')
        setDescription('')
        setText('')
        setImgNumber(0)
        setColSize('')

    }

    const handleSetPost = (e) => {
        e.preventDefault()

        document && console.log(document)
        document && console.log(error)

        if (document) {
            setUrl(document.url)
            setTitle(document.title)
            setTags(document.tags)
            setAuthor(document.author)
            setPostDate(document.postDate)
            setDescription(document.description)
            setText(document.text)
            setImgNumber(document.imgNumber)
            setColSize(document.colSize)
        }
    }

    const handleUpdatePost = async () => {
        const ref = doc(db, 'blog', `${postPrefix}-${url}`)

        const blogPost = {
            url,
            title,
            tags,
            author,
            postDate,
            description,
            text,
            imgNumber,
            colSize
        }

        await setDoc(ref, blogPost)

        console.log(error)

        setUrl('')
        setTitle('')
        setTags([])
        setAuthor('')
        setPostDate('')
        setDescription('')
        setText('')
        setImgNumber(0)
        setColSize('')
    }

    return (
        <div className="flex column justify-center align-center full-width">
            <div className="flex column justify-center align-center fixed-width">
                <form onSubmit={handleSubmit} className='add-post'>
                    <label>
                        <span>URL</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setUrl(e.target.value)}
                            value={url}
                        />
                    </label>
                    <label>
                        <span>Tytuł</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </label>
                    <label>
                        <span>Kategoria</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setTags(e.target.value)}
                            value={tags}
                        />
                    </label>
                    <label>
                        <span>Autor</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setAuthor(e.target.value)}
                            value={author}
                        />
                    </label>
                    <label>
                        <span>Data publikacji</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setPostDate(e.target.value)}
                            value={postDate}
                        />
                    </label>
                    <label>
                        <span>Opis</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />
                    </label>
                    <label>
                        <span>Tekst</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setText(e.target.value)}
                            value={text}
                            className='text-area'
                        />
                    </label>
                    <label>
                        <span>Wielkość galerii</span>
                        <input
                            required 
                            type="number" 
                            onChange={(e) => setImgNumber(e.target.value)}
                            value={imgNumber}
                        />
                    </label>
                    <label>
                        <span>Szerokość galerii</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setColSize(e.target.value)}
                            value={colSize}
                        />
                    </label>
                    <button className="btn">Dodaj post</button>
                </form>
            </div>
            <div>
                <form onSubmit={handleSetPost}>
                    <label>
                        <span>Id posta</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setPostId(e.target.value)}
                            value={postId}
                        />
                    </label>
                    <label>
                        <span>Prefix posta</span>
                        <input
                            required 
                            type="text" 
                            onChange={(e) => setPostPrefix(e.target.value)}
                            value={postPrefix}
                        />
                    </label>
                    <button className="btn">ustaw post</button>
                </form>

                <button className="btn" onClick={handleUpdatePost}>aktualizuj post</button>
            </div>
        </div>
    )
}
