//basic
import { useParams } from "react-router-dom"
import { useState } from "react"

//custom hooks
import { useCollection } from "../hooks/useCollection"

export default function Tags({ changeFilter }) {
  const [currentFilter, setCurrentFilter] = useState('all')

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter)
    changeFilter(newFilter)
  }

  const { documents } = useCollection('tags')
  const { querry } = useParams()

  console.log(querry)

  return (
    <div className="flex align-center justify-center wrap fixed-width">
      {documents && documents.map((item, x) => (
        <button 
          key={x}
          className={currentFilter === item.name ? 'blog-tag-name-active' : 'blog-tag-name'} 
          onClick={() => handleClick(item.name)}
        >
          <span>{item.name}</span>
        </button>
      ))}
    </div>
  )
}
