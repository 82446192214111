//components
import Gallery from '../components/Gallery'

export default function Portfolio() {
  
  return (
    <div className='flex column justify-center align-center'>
        <h1 className='text-center'>Portfolio</h1>
        <p className='text-center fixed-width'>Poniżej prezentuję niektóre z moich kadrów I filmów. Mam ich w swoim dorobku już niezliczoną ilość, dlatego zdecydowałem się w tym miejscu pokazać te najbardziej reprezentatywne dla mojego stylu. Selekcja ta pokazuje też różnorodność tematów jakie dane było mi fotografować i filmować.</p>
        <Gallery imgNumber={187} url={'images'} place={'portfolio'}/>
    </div>
  )
}
