//custom hooks
import { useDocument } from '../hooks/useDocument'

//components
import Socials from './Socials'
import Separator from './Separator'

export default function Contact() {

  const { document, error } = useDocument('start-other-sections', 'contact-section')

  return (
    <>
    {error && <div>{error}</div>}
    {document&& (
      <div id='contact' className='flex justify-center align-between full-width'>
        <div className='flex column justify-center align-center fixed-width'>
          <h2 className='text-center'>{document.heading}</h2>
          <p className='text-center fixed-width-700'>{document.text}</p>
          <h2 className='text-center'>{document.subheading}</h2>
          <Socials cssClass='contact-icons' />
        </div>
      </div>
    )}
    <Separator />
    </>
  )
}
