//basic
import { motion } from "framer-motion"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"

//custom hooks
import { useCollection } from '../hooks/useCollection'

export default function Oferta() {

  const { documents } = useCollection('offer')

  const variants = {
		iLeft: {
			x: -200,
			opacity: 0
		},
		iRight: {
			x: 200,
			opacity: 0
		},
		final: {
			x: 0,
			opacity: 1,
			transition: {
				delay: .5,
				duration: .8
			}
		}
	}
  
  return (
    <>
    {documents && (
      <div id="oferta" className="flex column justify-center align-center full-width">
          <h2 className='text-center fixed-width-700'>Oferta fotografii <span style={{color: 'rgb(255, 165, 0)'}}>ŚLUBNEJ</span>, OKOLICZNOŚCIOWEJ, INDYWIDUALNEJ i KOMERCYJNEJ</h2>
          <div className='flex column fixed-width'>
            {documents.map(i => {
              if (i.item % 2 !== 0) {
                return <div key={i.id} className='flex justify-center align-center offer-row res-column'>
                  <div className='offer-text w-50 flex column justify-center align-start'>
                    <div className='full-width'><ReactMarkdown>{i.heading}</ReactMarkdown></div>
                    <div><ReactMarkdown>{i.text}</ReactMarkdown></div>
                  </div>
                  <motion.div 
                    className='flex justify-end align-center w-50'
                    initial="iRight" whileInView="final" viewport={{ once: true }} variants={variants}
                    whileHover={{
                      scale: 1.1,
                      transition: { duration: 1 },
                      }}
                  >
                    <img src={i.img} alt={i.alt} className='' />
                  </motion.div>
                </div>
              } else {
                return <div key={i.id} className='flex justify-center align-center offer-row res-column res-inver'>
                  <motion.div 
                    className='flex column w-50 justify-center align-start'
                    initial="iLeft" whileInView="final" viewport={{ once: true }} variants={variants}
                    whileHover={{
                      scale: 1.1,
                      transition: { duration: 1 },
                      }}
                  >
                    <img src={i.img} alt={i.alt} className='' />
                  </motion.div>
                  <div className='flex column justify-end align-end offer-text w-50 left-20'>
                    <div className='full-width text-right'><ReactMarkdown>{i.heading}</ReactMarkdown></div>
                    <div className='text-right'><ReactMarkdown>{i.text}</ReactMarkdown></div>
                  </div>
                </div>
              }
            })}
          </div>
      </div>
    )}
    </>
  )
}
