//basic
import ReactMarkdown from 'react-markdown'
import { useState, useEffect } from 'react'
import { AnimatePresence, motion, } from 'framer-motion';

//costom hooks
import { useCollection } from '../hooks/useCollection'
import { useImages } from '../hooks/useImages'

//components
import Separator from './Separator'

export default function StartMain() {

	const { documents } = useCollection('startPage')
	const { images } = useImages('startPage-gallery')
	const [city, setCity] = useState(0)
	const cities = ['LUBLINIE', 'RADZYNIU PODLASKIM', 'ŁUKOWIE', 'MIĘDZYRZECU PODLASKIM', 'PARCZEWIE']

	useEffect(() => {
    const interval = setInterval(() => {
      if (city === 4) {
        setCity(0);
      } else {
        setCity(prevCity => prevCity + 1);
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [city, setCity]);

	const variants = {
		initial: {
			opacity: 0,
			y: -30,
			transition: { 
				duration: 0.5 
			}
		},
    animate: {
			opacity: 1,
			y: 0,
			transition: { 
				duration: 0.5 
			}
		},
    exit: {
			opacity: 0,
			y: 30,
			transition: { 
				duration: 0.5 
			}
		}
	}

	return (
		<>
		{documents && (
			<div id='main' className='flex column justify-center align-center full-width'>
				<div id='main-paragraph' className='flex column justify-center align-center fixed-width'>
					<div className='text-center fixed-width-700 animate-header-box'>
						<h1 className='animate-header-wrapper'>Fotograf czy kamerzysta w </h1>
						<AnimatePresence>
							<motion.h1 className='animate-header ' key={city} initial='initial' animate='animate' exit='exit' variants={variants} >{cities[city]}</motion.h1>
						</AnimatePresence>
						<h1 className='animate-header-wrapper'> i w województwie lubelskim</h1>
					</div>
					<ReactMarkdown>{documents[0].text}</ReactMarkdown>
					<div className='flex justify-between align-center res-column'>
						<ReactMarkdown className='w-60'>{documents[1].text}</ReactMarkdown>
						<div className="flex justify-center align-center w-40">
							<motion.img className="" id="przyslona" src="../assets/przyslona_hrp.svg" alt="Rysunek przysłony" initial={{rotate: 0}} animate={{rotate: 360}} transition={{duration: 5, ease: "linear", repeat: Infinity}}/>
						</div>
					</div>
				</div>
				{/* <div id="_galeria" className='flex justyfi-center align-center fixed-width res-column'>
					{images && images.map(image => (
						<motion.div 
							className='galery-item' 
							key={image.id}
							whileHover={{
								scale: 1.1,
								transition: { duration: 1 },
							}}
						>
							<img src={image.img} alt={image.alt} className='full-width mono'/>
						</motion.div>
					))}
				</div> */}
			</div>
		)}
		{/* <Separator /> */}
		</>
	)
}