import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";

export default function BlogCard({document}) {

    return (
        <>
            <div className='post-title'><h3>{document.title}</h3></div>
            <NavLink to={`/blog/${document.id}`}><img src={`../assets/blog/${document.url}.jpeg`} alt={document.url} /></NavLink>
            <p className='post-meta'><FaUserAlt/>{document.author}   <span>|</span>   <FaCalendarAlt/>{document.postDate}</p>
            <p className='post-desc'>{document.description}</p>
            <p className='post-tags'>{document.tags.map(tag => <span key={tag}>{` | ${tag}`}</span>) }</p>
            <div className='blog-btn'><NavLink to={`/blog/${document.id}`}><div>Czytaj dalej...</div></NavLink></div>
        </>
    )
}
