// basic
import { motion } from 'framer-motion'

//icons
import { FaHeart } from 'react-icons/fa'
import { FaCog } from 'react-icons/fa'
import { FaCamera } from 'react-icons/fa'
import { FaGem } from 'react-icons/fa'
import { FaUsers } from 'react-icons/fa'


//custom hooks
import { useCollection } from '../hooks/useCollection'

//components
import Separator from './Separator'

export default function Icons() {

	const icons = [<FaHeart/>, <FaCog/>, <FaCamera/>, <FaGem/>, <FaUsers/>]

	const { documents } = useCollection('start-icons')

	const container = {
		initial: {
			y: -50,
			opacity: 0
		},
		final: {
			y: 0,
			opacity: 1,
			transition: {
				duration: 1, 
				staggerChildren: .3
			}
		}
	}

	const itemAnim = {
		initial: {
			y: -50,
			opacity: 0
		},
		final: {
			y: 0,
			opacity: 1
		},
		active: {
			color: 'rgb(255, 165, 0)',
			y: -30,
      transition: { 
        duration: 0.8
      }
		}
	}

	return (
		<>
		{documents && (
			<div className='flex justify-center full-width'>
				<motion.div className='flex justify-start fixed-width res-column' initial='initial' whileInView='final' variants={container} viewport={{ once: true }} >
					{documents.map((item, x)=> (
						<motion.div 
							key={item.id} 
							className="flex column justify-start align-center"
							variants={itemAnim}
						>
							<motion.div 
								className="flex column justify-start align-center" 
								whileHover='active'
								variants={itemAnim}
							>
								<div className='icon-svg'>{icons[x]}</div>
								<p className='icon-header text-center'>{item.heading}</p>
								<p className="icon-text text-center">{item.text}</p>
							</motion.div>
								
						</motion.div>
					))}
				</motion.div>
			</div>
		)}
		<Separator />
		</>
	)
}