import { NavLink } from "react-router-dom"

export default function Navigation({ handleModal }) {
	return(
		<div className="flex align-center">
      <ul className="flex justify-center align-center navigation">
        <li key='1' onClick={handleModal}><NavLink to='/'>Start</NavLink></li>
        <li key='2' onClick={handleModal}><NavLink to='/oferta'>Oferta</NavLink></li>
        <li key='3' onClick={handleModal}><NavLink to='/portfolio'>Portfolio</NavLink></li>
        <li key='4' onClick={handleModal}><NavLink to='/blog'>Blog</NavLink></li>
        <li key='5' onClick={handleModal}><NavLink to='/bio'>O mnie</NavLink></li>
        <li key='6' onClick={handleModal}><NavLink to='/kontakt'>Kontakt</NavLink></li>
        {/* <li key='7' onClick={handleModal}><NavLink to='/dodaj-post'>Dodaj</NavLink></li> */}
		  </ul>
    </div>
	)
}