import React from 'react'
import HeroCard from './HeroCard'

export default function HeroCards() {

  const cards = [
    {
      value: 12,
      heading: 'LAT',
      desc: 'doświadczenia'
    },
    {
      value: '120',
      heading: 'TYŚ',
      desc: 'fotografii'
    },
    {
      value: 112,
      heading: 'zrealizowanych',
      desc: 'filmów'
    },
    {
      value: 135,
      heading: 'wesel',
      desc: 'na koncie'
    }
  ]

  return (
    <div className='hero-cards'>
      {cards.map(card => (<HeroCard card={card} />))}
    </div>
  )
}
