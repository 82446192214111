//basic
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

//custom hooks
import { useCollection } from '../hooks/useCollection'

export default function Pagination({document}) {

  const { documents } = useCollection('blog')
  const [blogList, setBlogList] = useState([])
  const currentPid = document.pid
  const prevPost = `/blog/${blogList[currentPid - 1]}`
  const nextPost = `/blog/${blogList[currentPid + 1]}`

  useEffect(() => {
    if (blogList) setBlogList([])
    documents && documents.map(item => (
        setBlogList(prevBlogList => [...prevBlogList, item.id])
    ))
  }, [documents])

console.log(blogList)

  return (
    <div id='pagination' className='flex justify-start align-center full-width'>
      {!(currentPid === 0) ? <NavLink to={prevPost}><button className='pagin-btn'>Poprzedni post</button></NavLink> : <></>}
      <NavLink to='/blog'><button className='pagin-btn'>Wróć do listy</button></NavLink>
      {(currentPid < blogList.length -1) ? <NavLink to={nextPost}><button className='pagin-btn'>Następny post</button></NavLink> : <></>}
    </div>
  )
}
