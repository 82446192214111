//components import
import Contact from '../components/Contact'

export default function Kontakt() {
  return (
    <div id='kontakt' className='flex column justify-center align-between full-width'>
      <Contact />
    </div>
  )
}
