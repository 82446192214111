//custom hooks
import { useDocument } from '../hooks/useDocument'

//components
import Socials from './Socials'
import Navigation from './Navigation'

export default function Footer() {

  const { document, error } = useDocument('start-other-sections', 'footer-section')

  return (
    <>
    {error && <div>{error}</div>}
    {document && (
      <div id='footer' className='flex column justify-center align-center full-width'>
        <div className='flex justify-center align-center fixed-width res-column res-center'>
          <div className='footer-01'>
            <h2>{document.heading}</h2>
            <p>{document.text}</p>
          </div>
          <div className='footer-02 flex justify-center align-center'>
            <img src={document.logo} alt={document.alt} />
          </div>
          <div className='footer-03 flex column justify-center align-end'>
            <Navigation />
            <Socials cssClass={'footer-icons'} />
          </div>
        </div>
        <div className='copy'><p>2023 - Wszelkie prawa zastrzeżone - Hubert Pulik | HRP Studio </p></div>
      </div>
    )}
    </>
  )
}
