//basic
import { useState, useEffect } from "react"

//custom hooks
import { useDocument } from '../hooks/useDocument'

export default function Services() {

  const [active, setActive] = useState(0)
  const { document, error } = useDocument('start-other-sections', 'offer-section')
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (document) {
      setCount(document.count)
    }
  }, [document])

  useEffect(() => {
    const interval = setInterval(() => {
      if (active === count) {
        setActive(prevActive => prevActive - count);
      } else {
        setActive(prevActive => prevActive + 1);
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [active, setActive, count]);

  return (
    <>
      {error && <div>{error}</div>}
      {document &&  
        <div>
          <div className="flex column justify-center align-center fixed-width">
            <h3 id='offer-list' className='text-center'>
              {document.services.map((service, x) =>
                active === x ? <span key={x} className='service-span service-span-active'> &bull;{service}</span> : <span key={x} className='service-span'> &bull;{service}</span>)
              }
            </h3>
          </div>
        </div>
      }
    </>
  )
}
