import { AnimatePresence, motion, } from 'framer-motion';

import Logo from './Logo'
import Socials from './Socials'
import Navigation from './Navigation';

export default function Mobilemenu({ handleModal }) {
  return (
    <AnimatePresence>
        <motion.div 
            className='menu-box'
            initial={{ y: -600 }}
            animate={{ y: 0 }}
            exit={{ y: -600 }}
            transition={{ duration: 0.7, delay: 0.25, ease: 'linear' }}
        >
            <Logo />
            <div className='mobile-nav-holder'>
             <Navigation handleModal={handleModal}/>
            </div>
            <Socials cssClass={'footer-icons'} />
            <div><h2 onClick={handleModal}>x</h2></div>
        </motion.div>
    </AnimatePresence>
  )
}
