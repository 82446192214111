import HearoImage from "./Hero/HearoImage";
import HeroBtns from "./Hero/HeroBtns";
import HeroCards from "./Hero/HeroCards";
import HeroHeader from "./Hero/HeroHeader";
import HeroText from "./Hero/HeroText";

export default function Hero() {
  return (
    <div className="hero-box">
      <div className="hero-top">
        <div className="hero-top-left">
          <HeroHeader />
          <HeroText />
          <HeroBtns />
          <div className="hero-desktop">
            <HeroCards />
          </div>
        </div>
        <div className="hero-top-right">
          <HearoImage />
        </div>
      </div>
      <div className="hero-bottom"> 
        <div className="hero-mobile">
          <HeroCards />
        </div>
      </div>
    </div>
  )
}
